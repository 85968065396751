import createStore from 'zustand/vanilla';
import { removeController, updateController } from './controller';
import {
  GraphNode,
  NatureDefinition,
  NatureInstance,
  PropertyAccess,
  SystemObject,
  SystemObjectData,
  SystemObjectDetails,
  SystemObjectRef,
  SystemObjectResult,
} from './types';
import { deepCompare, transformProperties } from './utils';

export interface Requester {
  query<T>(op: string, args: any, signal?: AbortSignal): Promise<T>;
  mutate<T>(op: string, args: any): Promise<T>;
}

const roots = new Set<number>();
const requester: Requester = {
  mutate() {
    return Promise.reject('No requester configured.');
  },
  query() {
    return Promise.reject('No requester configured.');
  },
};

export const natures: Array<NatureDefinition> = [];

export const nodes = createStore(() => ({} as Record<string, GraphNode>));

export function configureRequester(newRequester: Requester) {
  Object.assign(requester, newRequester);
}

function sortNodes(a: SystemObject, b: SystemObject) {
  let nameA = '',
    nameB = '';

  if (a.name && b.name) {
    nameA = a.name;
    nameB = b.name;
  } else {
    nameA = (a.properties?.find((p) => p.label === 'name')?.value as string) || '';
    nameB = (b.properties?.find((p) => p.label === 'name')?.value as string) || '';
  }

  return nameA.toLocaleLowerCase().localeCompare(nameB.toLocaleLowerCase());
}

function constructSystemObject(so: SystemObject): SystemObject {
  const item = {
    ...so,
    ...transformProperties(so?.properties ?? []),
    children: so?.children?.map(constructSystemObject) ?? [],
    parents: so?.parents ?? [],
  };

  return item;
}

export function initializeGraph(objectRoots: Array<SystemObject>, initialNatures: Array<NatureDefinition>) {
  const rootNodes = objectRoots.map(toNode);
  natures.push(...initialNatures);
  rootNodes.forEach((r) => roots.add(r.id));
  updateNodes(rootNodes);
}

function toNode(item: SystemObject): GraphNode {
  const details = constructSystemObject(item);

  return {
    id: item.id,
    childNodes: item?.children?.sort(sortNodes)?.map((m) => m.id) ?? [],
    facts: {},
    loaded: false,
    loading: false,
    deleted: false,
    details,
  };
}

function updateNodes(newNodes: Array<GraphNode>) {
  const snapshot = nodes.getState();
  const update: Record<string, GraphNode> = {};
  const queue = [...newNodes];

  while (queue.length > 0) {
    const node = queue.pop();

    if (node) {
      const existing = snapshot[node.id];

      if (!existing || newNodes.includes(node)) {
        update[node.id] = node;
      } else if (existing && !existing.loaded) {
        update[node.id] = {
          ...existing,
          details: {
            ...existing.details,
            children: node.details?.children || existing.details.children,
          },
          childNodes: node.childNodes || existing.childNodes,
        };
      }

      const children = node?.details?.children?.map(toNode) ?? [];

      queue.push(...children);
    }
  }

  nodes.setState(update);
}

function updateNodeInParents(id: number, details: SystemObject) {
  if (typeof id !== 'number' || !details) return;

  const parents = details?.parents || [];
  for (const parent of parents) {
    updateNode(parent.id, (n) => {
      const children = n?.details?.children?.map((c) => {
        if (c.id === id) {
          return { ...c, ...details };
        }
        return c;
      });

      return {
        details: {
          ...n.details,
          children,
        },
      };
    });
  }
}

async function resyncNode(id: number) {
  const { object: result } = await queryNode(id).catch(() => ({} as SystemObjectData));

  // Update the current node
  updateNode(id, (node) => ({
    ...node,
    details: constructSystemObject(result),
  }));

  // Update the current node in parent objects (children property)
  updateNodeInParents(id, result);
}

function updateNode(id: number, fn: (node: GraphNode) => Partial<GraphNode>) {
  const state = nodes.getState();
  const oldItem = state[id];
  const newItem = fn(oldItem);
  updateNodes([
    {
      ...oldItem,
      ...newItem,
    },
  ]);
}

function moveNode(id: number, parents: Array<SystemObject>) {
  const state = nodes.getState();
  const node = state[id];

  if (node) {
    const oldParents = node.details?.parents || [];
    const newParents = parents.map((p) => findGraphNode(p.id)!);

    updateNode(id, (node) => ({
      details: {
        ...node.details,
        parents,
      },
    }));

    for (const parent of oldParents) {
      updateNode(parent.id, (node) => ({
        childNodes: node.childNodes.filter((c) => c !== id),
      }));
    }

    for (const parent of newParents) {
      updateNode(parent.id, (node) => ({
        childNodes: [...node.childNodes, id],
      }));
    }
  }
}

export function retrieveGraphNode(id: number): GraphNode {
  const state = nodes.getState();
  const node = state[id];

  if (!node) {
    loadGraphNode(id);
  }

  return node;
}

export function findGraphNode(id?: number): GraphNode | undefined {
  if (typeof id === 'number') {
    const state = nodes.getState();
    return state[id];
  }

  return undefined;
}

export function findGraphNodes(ids: Array<number>): Array<GraphNode> {
  const state = nodes.getState();
  return ids.map((id) => state[id]).filter(Boolean);
}

export function getRootIds() {
  return Array.from(roots);
}

export function mapSystemObjects(nodes: Array<GraphNode>) {
  return nodes.map((node) => node.details);
}

export function getRootNodes() {
  const nodes = findGraphNodes(getRootIds());
  return mapSystemObjects(nodes);
}

export async function queryNode(id: number, anon = false, key = 'id'): Promise<SystemObjectData> {
  if (typeof id !== 'number') {
    throw new Error('Received not valid id: ' + id);
  }

  return await requester.query<SystemObjectData>('co4CoreGIGet', { [key]: id, hidden: anon });
}

export async function loadGraphChildren(id: number, anon = false) {
  const state = nodes.getState();
  const node = state[id];

  // load only if its not loaded and not loading and not all children are loaded
  if (!node.loaded && !node.loading) {
    await loadGraphNode(id, anon);
  }
}

async function completeNode(id: number, anon = false, key = 'id') {
  const { object: details } = await queryNode(id, anon, key).catch((err) => {
    const deleted = !!err?.list?.some((m) => m?.message?.toLowerCase().includes('not found'));

    if (key === 'id') {
      updateNode(id, () => {
        return {
          ...toNode({ id, name: 'Loading...', natures: [] } as SystemObject),
          id,
          loaded: true,
          loading: false,
          deleted,
        };
      });
    }
    return { object: {} } as SystemObjectData;
  });

  const childNodes = details.children?.sort(sortNodes)?.map((m) => m.id);

  return {
    loaded: true,
    loading: false,
    details: constructSystemObject(details),
    childNodes,
  } as GraphNode;
}

export async function completeNodes(loadedNodes: Array<GraphNode>, anon = true) {
  const unloadedNodes = loadedNodes.filter((child) => !child.loaded && !child.loading);

  if (unloadedNodes.length > 0) {
    const initial = unloadedNodes.reduce((obj, node) => {
      obj[node.id] = {
        ...node,
        loading: true,
      };
      return obj;
    }, {});

    nodes.setState(initial);

    const newStates = await Promise.all(unloadedNodes.map((node) => completeNode(node.id, anon)));
    const snapshot = nodes.getState();
    const updated = unloadedNodes.map((node, i) => {
      const c = snapshot[node.id];
      const s = newStates[i];

      return {
        ...c,
        ...s,
      };
    });

    updateNodes(updated);
  }
}

export async function loadGraphNodeByNiid(niid: number, anon = false) {
  return await loadGraphNode(niid, anon, 'niid');
}

export async function loadGraphNode(id: number, anon = false, key = 'id') {
  if (key === 'id') {
    updateNode(id, () => ({
      loading: true,
    }));
  }

  const newState = await completeNode(id, anon, key);

  if (typeof newState?.details?.id === 'number') {
    updateNode(newState.details.id, () => newState);
  }
  return newState;
}

export function loadGraphNodes(ids: Array<number>) {
  const current: Array<GraphNode> = [];
  const snapshot = nodes.getState();
  const promises: Array<Promise<void>> = [];

  for (let i = 0; i < ids.length; i++) {
    const id = ids[i];
    const node = snapshot[id];
    current.push(node);

    if (!node?.loaded && !node?.deleted) {
      if (!node?.loading) {
        // load the node
        promises.push(
          loadGraphNode(id, true).then((res) => {
            // load parents of the node if not loaded
            // (when node is visited directly using URL with missing nodes in the path)
            const parents = res?.details?.parents?.map((v) => v?.id) || [];
            loadGraphNodes(parents);
          }),
        );
      }

      current.push(
        ...ids.slice(i + 1).map(
          (id): GraphNode => ({
            id,
            loaded: false,
            deleted: false,
            loading: true,
            facts: {},
            childNodes: [],
            details: {
              id,
              hidden: false,
              name: '...',
              images: [],
              icon: undefined,
              natures: [],
              parents: [],
              path: [],
              properties: [],
              dependents: [],
            },
          }),
        ),
      );

      break;
    }
  }

  return current;
}

export async function addGraphNode(parents: Array<number>, input: Partial<Omit<SystemObjectDetails, 'parents'>>) {
  const properties = Object.entries(input)?.map(([label, value]) => ({
    label,
    value,
    access: PropertyAccess?.WRITE,
  }));
  const hidden = [undefined, null]?.includes(input?.name);

  const res = await requester.mutate<SystemObjectResult>('co4CreateSystemObject', {
    parents,
    hidden,
    properties,
  });

  let newChild: SystemObject;

  const parentPath = findGraphNode(parents[0])?.details?.path || [];

  if (res) {
    const path = parentPath.length ? parentPath.concat(res.object.id) : [];
    newChild = {
      ...input,
      name: input?.name || '',
      id: res?.object?.id,
      natures: [],
      path,
      hidden,
      properties: res?.object?.properties ?? [],
    };
  }

  for (const parent of parents) {
    const parentNode = findGraphNode(parent);
    const children = [...parentNode?.details.children]?.concat(newChild).sort((a, b) => a.name.localeCompare(b.name));

    updateNode(parent, (n) => ({
      loaded: n.loaded,
      loading: false,
      details: {
        ...parentNode?.details,
        children: parentNode?.details.children?.concat(newChild),
      },
      childNodes: children.map((c) => c.id),
    }));
  }

  return res;
}

export async function getDependents(id: number): Promise<Array<NatureInstance>> {
  const res = await requester.query<{ dependents: Pick<SystemObject, 'natures'> }>('co4GetDependents', { id });

  updateNode(id, (node) => ({
    ...node,
    details: {
      ...node?.details,
      natures: node?.details?.natures.map((n) => ({
        ...n,
        dependents: res.dependents.natures.find((d) => d.id === n.id)?.dependents,
      })),
    },
  }));

  return res?.dependents?.natures ?? [];
}

export async function deleteGraphNode(id: number) {
  const node = findGraphNode(id);

  const res = await requester.mutate('co4DeleteSystemObjects', {
    ids: [id],
  });

  if (node) {
    const parents = node.details?.parents || [];

    updateNode(id, () => ({
      deleted: true,
    }));

    for (const parent of parents) {
      updateNode(parent.id, (p) => ({
        loaded: p.loaded,
        loading: p.loading,
        details: {
          ...p.details,
          children: p.details.children?.filter((c) => c.id !== id),
        },
        childNodes: p.childNodes.filter((c) => c !== id),
      }));
    }
  }

  return res;
}

export async function loadFact<T>(
  soid: number | undefined,
  op: string,
  args: Record<string, number | string>,
  force = false,
) {
  const controller = updateController(`fact-${op}-${soid}`);
  const so = findGraphNode(soid);

  if (!force && so?.facts && op in so.facts) {
    return so.facts[op];
  }

  const result = await requester
    .query<T>(op, args, controller.signal)
    .finally(() => removeController(`fact-${op}-${soid}`));

  updateNode(soid, (node) => {
    if (!node) {
      return {
        id: soid,
        loaded: false,
        loading: false,
        childNodes: [],
        details: {
          id: soid,
          name: '...',
          images: [],
          parents: [],
          natures: [],
          path: node?.details?.path ?? [],
          properties: [],
          dependents: [],
          children: undefined,
          hidden: false,
          icon: undefined,
        },
        facts: {
          [op]: result,
        },
      };
    } else {
      return {
        facts: {
          ...node.facts,
          [op]: result,
        },
      };
    }
  });

  return result as T;
}

export async function mutateFact<T>(
  soid: number | undefined,
  op: string,
  payload: Record<string, any>,
  clear: boolean = false,
) {
  const result = await requester.mutate<T>(op, payload);

  if (soid !== undefined) {
    let node = findGraphNode(soid);
    /* clear the facts if the "clear" is true */
    if (clear) {
      const facts = node?.facts ?? {};
      delete facts[op];

      updateNode(soid, (node) => ({
        details: {
          ...node.details,
          natures: node.details.natures?.filter((n) => n.id !== payload?.niid) ?? [],
        },
        facts,
      }));

      // get the updated node and update it in parents
      node = findGraphNode(soid);
      updateNodeInParents(soid, node?.details);
    } else if (!(op in node?.facts)) {
      /* If op doesn't exist in facts, refresh the natures */
      await resyncNode(soid);
    }
  }
  return result as T;
}

async function updateProperties(id: number, details: Partial<SystemObjectDetails>) {
  const current = findGraphNode(id);
  const properties = current?.details?.properties ?? [];

  const newProps = [],
    updatedInputs = [],
    propsToRemove = [];

  Object.entries(details).forEach(([label, value]) => {
    if (!deepCompare(value, current?.details?.[label])) {
      const id = properties.find((p) => p.label === label)?.id;

      if (typeof id === 'number') {
        if (value === undefined || value === null) {
          propsToRemove.push(id);
        } else {
          updatedInputs.push([id, value]);
        }
      } else if (value !== undefined && value !== null) {
        newProps.push({
          label,
          value,
          access: PropertyAccess.WRITE,
        });
      }
    }
  });

  if (propsToRemove.length > 0) {
    await requester.mutate('removeObjectProperties', { ids: propsToRemove });
  }

  if (newProps.length > 0) {
    await requester.mutate('setObjectProperties', { soid: id, properties: newProps });
  }

  if (updatedInputs.length > 0) {
    let ids = [],
      values = [];

    for (let [id, value] of updatedInputs) {
      ids.push(id);
      values.push(value);
    }

    await requester.mutate('updateObjectProperties', { ids, values });
  }
}

export async function updateGraphNode(id: number, input: Partial<SystemObjectDetails>) {
  const { parents = [], path, ...rest } = input;
  const current = findGraphNode(id);

  if (!!current?.details?.name !== !!input?.name) {
    await requester.mutate('co4UpdateSystemObject', { id, hidden: !input?.name });
  }

  const currentPIds = current?.details?.parents?.map(({ id }) => id) || [];
  const pIds = parents.map((p) => p?.id);
  const parentsChanged = !deepCompare(currentPIds, pIds);

  if (parentsChanged) {
    await requester
      .mutate('co4MoveSystemObject', { id, parents: parents.map((p) => p?.id) })
      .then(() => moveNode(id, parents));
  }

  const res = await updateProperties(id, rest);

  updateNode(id, (state) => ({
    details: {
      ...state.details,
      ...rest,
      labels: rest?.labels ?? [],
      parents,
    },
  }));

  return res;
}

export function getSystemObjectRefs(segment: string): Array<number> {
  return segment
    .split('-')
    .map((x) => parseInt(x, 16))
    .filter((x) => !isNaN(x));
}

export function getSystemObjectLink(objects: Array<SystemObjectRef>) {
  return objects.map((obj) => obj.id.toString(16)).join('-');
}

export function getRootTrail() {
  const [root] = roots;
  return root.toString(16);
}

export async function getObjectPath(id?: number, niid?: number) {
  if (typeof id !== 'number' && typeof niid !== 'number') {
    return '';
  }

  const object = findGraphNode(id);
  const path = object?.details?.path;

  if (path?.length) {
    return formatObjectPath(path);
  } else {
    const { path } = (await requester.query<{ path: number[] }>('co4GetObjectPath', { id, niid })) ?? {};
    return formatObjectPath(path);
  }
}

export function formatObjectPath(path: Array<number>) {
  return path.map((p) => p.toString(16)).join('-');
}
