import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { CategorizedObjects, GraphNode } from './types';
import {
  completeNodes,
  findGraphNode,
  getDependents,
  getSystemObjectRefs,
  loadFact,
  loadGraphNode,
  loadGraphNodes,
  natures,
  nodes,
} from './graph';

export function useSelectedSystemObjects() {
  const { trail = '' } = useParams<{ trail: string }>();
  return useNodes(trail);
}

export function useNodes(trail: string) {
  const [selected, setSelected] = useState<Array<GraphNode>>([]);

  useEffect(() => {
    const refs = getSystemObjectRefs(trail);
    setSelected(loadGraphNodes(refs));

    return nodes.subscribe((current, previous) => {
      if (refs.some((id) => previous[id] !== current[id])) {
        setSelected(loadGraphNodes(refs));
      }
    });
  }, [trail]);

  return selected;
}

export function useFact(soid: number, niid: number, op: string, force: boolean = false) {
  const node = useNode(soid);

  useEffect(() => {
    if (!node || !(op in node.facts)) {
      loadFact(soid, op, { niid }, force);
    }
  }, []);

  return node?.facts[op];
}

export function useNode(id?: number) {
  const [node, setNode] = useState(findGraphNode(id));

  useEffect(() => {
    if (typeof id === 'number') {
      if (node?.id !== id) {
        setNode(findGraphNode(id));
      }

      return nodes.subscribe((current, previous) => {
        const next = current[id];
        const prev = previous[id];

        if (next !== prev) {
          setNode(next);
        }
      });
    }
  }, [id]);

  return node;
}

export function useLoadedNode(id: number, anon: boolean = false) {
  const child = useNode(id);

  useEffect(() => {
    if (!child || (!child.loaded && !child.loading)) {
      loadGraphNode(id, anon);
    }
  }, [child]);

  return child;
}

export function useLoadedNodes(anon: boolean = false, load: boolean = true) {
  const [loadedNodes, setLoadedNodes] = useState(() => Object.values<GraphNode>(nodes.getState()));

  useEffect(() => {
    if (load) {
      completeNodes(loadedNodes, anon);
    }
  }, [loadedNodes]);

  useEffect(() => {
    return nodes.subscribe((m) => {
      setLoadedNodes(Object.values(m));
    });
  }, []);

  return loadedNodes;
}

export function useFilteredNodes(input: string, anonymous: boolean, display: string) {
  const loadedNodes = useLoadedNodes(anonymous, false);

  return useMemo(() => {
    let result = loadedNodes;

    if (input) {
      const tokens = input.toLowerCase().trim().split(/\W+/);
      result = result.filter((m) => {
        const n = m.details?.name;

        if (n) {
          const s = n.toLowerCase();
          return tokens.some((t) => s.includes(t));
        }

        return false;
      });
    }

    const nature = natures.find((m) => m.code === display);

    if (nature) {
      const nid = nature.id;
      result = result.filter((child) => child.details.natures.some((m) => m.id === nid));
    }

    return result;
  }, [input, loadedNodes, display]);
}

export function useCurrentSystemObject() {
  const selected = useSelectedSystemObjects();
  return selected.at(-1);
}

export function useParents(node: GraphNode) {
  const [parents, setParents] = useState<Array<GraphNode>>([]);

  useEffect(() => {
    const details = node.details;
    const tasks: Array<Promise<GraphNode>> = [];
    const parents = details.parents.map((p) => {
      const node = findGraphNode(p.id);

      if (!node) {
        tasks.push(loadGraphNode(p.id));
      }

      return node;
    });

    if (tasks.length) {
      Promise.all(tasks).then(() => setParents(details.parents.map((p) => findGraphNode(p.id)!)));
    }

    setParents(parents.filter(Boolean).map((p) => p!));
  }, [node]);

  return parents;
}

export function useCategorizedGraph(soids: Array<number>): Array<CategorizedObjects> {
  return useMemo(() => {
    if (soids.length) {
      const objects = soids.map(findGraphNode);

      const natureIds = [
        ...new Set(
          objects
            .filter(Boolean)
            .map((ob) => ob!.details?.natures)
            ?.flat()
            ?.map((d) => d?.nature?.id) ?? [],
        ),
      ];

      //Filtering by Nature Types
      const categories = natureIds.map((id) => {
        const natureTitle = natures.find((n) => n.id === id)?.code.replace(/_/g, ' ') ?? 'unknown';
        return {
          id,
          natureTitle,
          objects: objects.filter((ob) => ob!.details.natures?.some((n) => id === n?.id)).map((n) => n!),
        };
      });

      //The Objects with no nature types goes to unassigned
      const unassignedObjects = objects
        .filter((ob) => !ob!?.details?.natures?.some((n) => natureIds.includes(n?.id)))
        .map((n) => n!);

      if (unassignedObjects.length) {
        categories.push({
          id: 0,
          natureTitle: 'unassigned',
          objects: unassignedObjects,
        });
      }

      return categories;
    }

    return [];
  }, [soids]);
}

export function useDependents(id: number, refresh: boolean = false) {
  const node = useNode(id);
  const deps = node?.details?.natures ?? [];
  const [dependents, setDependents] = useState(deps);

  useEffect(() => {
    if (typeof id === 'number' && (refresh || !deps.length)) {
      getDependents(id).then(setDependents);
    }
  }, [id, refresh, deps.length]);

  return dependents;
}
